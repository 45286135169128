var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu as Dropdown } from "@headlessui/react";
import { IconV2 } from "../../icon_v2";
export const SplitbuttonOption = (_a) => {
    var { iconName, label, hideLabel } = _a, props = __rest(_a, ["iconName", "label", "hideLabel"]);
    const handleOnClick = (e) => {
        if (props.onClick) {
            props.onClick;
        }
    };
    const renderOption = (_jsxs(_Fragment, { children: [props.loading !== undefined ? (_jsx("div", { className: `mr-2 h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-t-transparent `, children: _jsx("span", { className: "sr-only", children: "Loading" }) })) : null, iconName !== undefined && (_jsx("span", { className: "flex max-h-5 items-center pl-2 pr-4", children: _jsx(IconV2, { type: "fa", icon: {
                        icon: typeof iconName === "string" ? ["far", iconName] : iconName,
                    } }) })), hideLabel !== true && _jsx("span", { className: "text-left text-sm", children: label })] }));
    return (_jsx(Dropdown.Item, Object.assign({}, props, { "data-eco-component": "menu-option", children: ({ active }) => (_jsx("button", { className: `${active ? "bg-container-medium dark:bg-dark-container-medium" : ""} text-primaryText dark:text-dark-primaryText group flex w-full items-center rounded-md px-2 py-2 text-sm`, onClick: handleOnClick, children: renderOption })) })));
};
